import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import { Container } from "react-bootstrap"

import QuoteIcon from "../../images/left-quote-icon.inline.svg"
import {
  containerClass,
  textClass,
  blockquoteClass,
  quoteClass,
  iconClass,
  rightIconClass,
  authorClass
} from "./PagePullQuote.module.scss"

export const fragment = graphql`
  fragment pagePullQuoteFragment on WpPage_Flexlayouts_FlexibleLayouts_PagePullQuote {
    quote
    author
  }
`

const PagePullQuote = ({ quote, author }) => {
  return (
    <div className="bg-pale-grey">
      <Container className="bg-white pt-3 pb-4">
        <div className={containerClass}>
          <QuoteIcon className={`${iconClass} me-3`} />
          <div className={textClass}>
            <blockquote className={blockquoteClass}>
              <span
                className={quoteClass}
                dangerouslySetInnerHTML={{
                  __html: quote
                }}
              />
            </blockquote>
            {author && (
              <cite
                className={`${quoteClass} ${authorClass}`}
                dangerouslySetInnerHTML={{
                  __html: `- ${author}`
                }}
              />
            )}
          </div>
          <QuoteIcon className={`${iconClass} ${rightIconClass}`} />
        </div>
      </Container>
    </div>
  )
}

PagePullQuote.propTypes = {
  quote: PropTypes.string.isRequired,
  author: PropTypes.string
}

export default PagePullQuote
